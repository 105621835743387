//React
import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//Data
import ReactGA from "react-ga4";
import * as amplitude from "@amplitude/analytics-browser";

//Components
import AppLayout from "components/AppLayout";
import AppLayoutCommunity from "components/AppLayoutCommunity";

//Pages
import Landing from "pages/Landing";
import Login from "pages/Login";
import Intent from "pages/Register/Intent";
import InstagramImpressions from "pages/Calculator/Impressions";

import ReportSample from "pages/Report/sample.js";
import Report from "pages/Report";
import CommunitySample from "pages/Community/sample.js";
import Community from "pages/Community";
import CampaignSample from "pages/Campaign/sample.js";
import Campaign from "pages/Campaign";

import Register from "pages/Register/Enterance";
import Kauth from "pages/Register/Kauth";
import Fauth from "pages/Register/Fauth";
import Guide from "pages/Register/Guide";
import Select from "pages/Register/Select";
import Reauth from "pages/Register/Reauth";
import Complete from "pages/Register/Complete";
import Fail from "pages/Error/RegisterError";
import Error from "pages/Error/PageError";
import Gauth from "./pages/Register/Gauth";
import YoutubeIntent from "./pages/Register/YoutubeIntent";
import Settings from "pages/Settings";
import Category from "pages/Category";
import useToast from "hook/useToast";
import { Apply } from "pages/Campaign/Apply";
import { Submit } from "pages/Campaign/Submit";
import useAmplitude from "hook/useAmplitude";

export const GlobalContext = createContext(null);

const RedirectTarget = function ({ login, register, sample }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (login) {
      navigate("/login", { replace: true });
      return;
    }
    if (register) {
      navigate("/app/register", { replace: true });
      return;
    }
    if (sample) {
      window.location.replace(
        `${process.env.REACT_APP_FRONTEND_HOST}/app/report/sample`
      );
      return;
    }
  }, []);
  return <></>;
};

function App() {
  const { handleToast, component: Toast } = useToast();
  const [isAmplitudeInitialized, setIsAmplitudeInitialized] = useState(false);
  const { updateUserProperties } = useAmplitude();

  useEffect(() => {
    ReactGA.initialize("G-X38Q8JL310");
  }, []);

  useLayoutEffect(() => {
    if (!isAmplitudeInitialized) {
      const userData = window.sessionStorage.getItem("userData");
      amplitude
        .init(
          process.env.REACT_APP_AMPLITUDE_API_KEY,
          userData?.kakaoUserData?.userId,
          {
            defaultTracking: {
              pageViews: true,
              sessions: true,
              formInteractions: true,
              fileDownloads: true,
            },
            flushIntervalMillis: 100,
          }
        )
        .promise.then(updateUserProperties)
        .then(() => setIsAmplitudeInitialized(true))
        .catch(console.error);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <GlobalContext.Provider
          value={{
            handleToast,
          }}
        >
          {Toast}
          <Routes>
            <Route path="/app/campaign/apply" element={<Apply />} />
            <Route path="/app/campaign/upload" exact element={<Submit />} />
            <Route path="/" exact element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/intent" element={<Intent />} />
            <Route path="/youtube-intent" element={<YoutubeIntent />} />
            <Route
              path="/caculator/impressions"
              element={<InstagramImpressions />}
            />

            <Route
              path="/app/report/sample"
              element={
                <AppLayout state="report">
                  <ReportSample />
                </AppLayout>
              }
            />
            <Route
              path="/app/report"
              element={
                <AppLayout state="report">
                  <Report />
                </AppLayout>
              }
            />
            {/* <Route
              path="/app/community/sample"
              element={
                <AppLayoutCommunity state="community">
                  <CommunitySample />
                </AppLayoutCommunity>
              }
            />
            <Route
              path="/app/community"
              element={
                <AppLayoutCommunity state="community">
                  <Community />
                </AppLayoutCommunity>
              }
            /> */}
            <Route
              path="/app/campaign/sample"
              element={
                <AppLayout state="campaign">
                  <CampaignSample />
                </AppLayout>
              }
            />
            <Route
              path="/app/campaign"
              element={
                <AppLayout state="campaign">
                  <Campaign />
                </AppLayout>
              }
            />
            <Route
              path="/app/settings"
              element={
                <AppLayout state="register">
                  <Settings />
                </AppLayout>
              }
            />
            <Route
              path="/app/category"
              element={
                <AppLayout state="register">
                  <Category />
                </AppLayout>
              }
            />

            <Route
              path="/app/*"
              element={
                <AppLayout state="register">
                  <Routes>
                    <Route path="/register" element={<Register />} />
                    <Route path="/register/kauth" element={<Kauth />} />
                    <Route path="/register/fauth" element={<Fauth />} />
                    <Route path="/register/gauth" element={<Gauth />} />
                    <Route path="/register/guide" element={<Guide />} />
                    <Route path="/register/select" element={<Select />} />
                    <Route path="/register/reauth" element={<Reauth />} />
                    <Route path="/register/complete" element={<Complete />} />
                    <Route path="/register/fail" element={<Fail />} />
                    <Route path="*" element={<Error />} />
                  </Routes>
                </AppLayout>
              }
            />

            <Route path="/web/report" element={<RedirectTarget login />} />
            <Route
              path="/register_select"
              element={<RedirectTarget register />}
            />
            <Route
              path="/analysis_report/sample"
              element={<RedirectTarget sample />}
            />

            <Route path="*" element={<Error />} />
          </Routes>
        </GlobalContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
